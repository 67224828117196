import React, { useState } from "react";
import UnderConstruction from "./components/UnderConstruction/UnderConstruction";
import "./App.css";
import DesciptionBlock from "./components/DescriptionBlock/DescriptionBlock";
import LinkedinButton from "./components/LinkedinButton/LinkedinButton";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/LangSelector/LanguageSelector";

function App() {
  const [isUnderConstruction, setIsUnderConstruction] = useState(true);

  const { t } = useTranslation();

  if (isUnderConstruction) {
    return <UnderConstruction />;
  }

  return (
    <div className="animated-background ">
      <LanguageSelector />
      <h1>EDUARDO ANDRES VARON</h1>
      <h2>Fullstack Developer</h2>
      {/* <h2>{t("description")}</h2> */}
      <DesciptionBlock />
      <LinkedinButton />
    </div>
  );
}

export default App;
