import { changeLanguage } from "i18next";
import "./LanguageSelector.css";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const languages = [
    { code: "en", label: "EN" },
    { code: "es", label: "ES" },
  ];

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <div className="language-selector-container">
      {languages.map((language) => (
        <button
          key={language.code}
          className={language.code === i18n.language ? "selected" : ""}
          onClick={() => changeLanguage(language.code)}
        >
          {language.label}
        </button>
      ))}
    </div>
  );
};

export default LanguageSelector;
