import React from "react";
import "./DescriptionBlock.css";
import { Trans, useTranslation } from "react-i18next";
import TextEffect from "../TextEffect/TextEffect";

const DesciptionBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="glass-morphism">
      <p>
        <Trans
          i18nKey="description"
          values={{ myName: "Eduardo" }}
          components={{
            1: <TextEffect />,
          }}
        ></Trans>
      </p>
    </div>
  );
};

export default DesciptionBlock;
